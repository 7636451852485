.react-datepicker {
  font-family: var(--chakra-fonts-body);
  font-size: 1rem;
}

.react-datepicker__day {
  color: var(--chakra-colors-brand-black-900);
}

.react-datepicker__day--selected {
  color: var(--chakra-colors-brand-black-100);
  background-color: var(--chakra-colors-brand-primary-purple);
}

.react-datepicker__day--selected:hover {
  background-color: var(--chakra-colors-brand-secondary-purpleDark);
}

.react-datepicker__day--outside-month {
  color: var(--chakra-colors-brand-black-400);
}

.react-datepicker__navigation-icon {
  top: 0.28rem;
}

.react-datepicker__current-month {
  color: var(--chakra-colors-brand-black-900);
  font-size: 1rem;
}

.react-datepicker__header {
  background: var(--chakra-colors-brand-black-100);
  border-bottom: none;
}

.react-datepicker__day-name {
  color: var(--chakra-colors-brand-black-900);
  font-weight: 600;
}